.officer-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    margin-bottom: 8%;
}

@media only screen and (max-width: 768px) {
    .officer-wrapper {
        padding: 2%;
        display: flex;
    flex-direction: column;
    }
  }

.officer-group > h3 {
    color: black;
    text-align: left;
    width: 100%;
    flex-grow: 2;
    font-size: 1.2rem;
}

.officer-group {
    background-color: rgba(255, 255, 255, .75);
   box-sizing: border-box;
}

.officer-card-group {
    display: flex;
    justify-content: space-between;
}

.officer-card-item {
    transition: transform 1s ease-out, opacity 1s ease-out, z-index 1s ease-out;
    transform: translateX(0);
    opacity: 1;
    box-sizing: border-box;
}

.officer-card-item p {
    padding: 0 3%;
}

.hidden-officer-top {
    transform: translateX(-500px);
    opacity: 0;
    z-index: -5;
}

.hidden-officer-bottom {
    transform: translateX(500px);
    opacity: 0;
    z-index: -5;
}
.row {
    display: flex;
    justify-content: center;
}