/*--------------------ARTICLE------------------------*/
.article-link, .article-link:hover, .article-link:active {
    text-decoration: none;
}

.article-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2% 1%;
    box-sizing: border-box;
    text-decoration: none !important;
}

.article-card p {
    color: #959595;
    text-decoration: none !important;
}

.article-card:hover {
    cursor: pointer;
    background-color: rgb(128, 128, 128, .25);
    color: white !important;
}

.article-card-image, .article-card-image-tall {
    width: 100%;
    margin-bottom: .6rem;
}

.article-card-image-tall {
    display: flex;
    justify-content: center;
}

.article-card-image > img {
    width: 100%;
    height: auto;
    /* max-height: 225px; */
}

.article-card-image-tall > img {
    max-height: 225px;
    max-width: 100%;
    border: 1px solid black;
}

.article-card > h4 {
    margin: 0 0 1% 0;
    font-family: Arial, sans-serif !important;
}

.article-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 2%;
    box-sizing: border-box;
}

.article-card-content > p:first-of-type {
    font-weight: bold;
    margin: 0;
    width: 100%;
}

.article-card-content > p:last-of-type {
    font-size: .8rem !important;
    width: 100%;
    display: flex;
    overflow: hidden;
    margin-bottom: 0;
}

.active-article, .active-article:hover {
    background-color: rgb(128, 128, 128, .75);
    cursor: pointer;
    text-decoration: none;
}

.active-article p {
    color: black !important;
}

.active-article:hover {
    color: white !important;
}

/*--------------------SERVICE------------------------*/

.service-card {
    box-sizing: border-box;
}

.service-card-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.service-card-name > h3 {
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
}

.service-card-detail {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.service-card-detail > p {
    text-align: center;
}

.service-card-link {
    text-align: center;
    background-color: #808080;
    color: white;
    box-sizing: border-box;
    margin: 1rem 5rem; 

}

.service-card-link:hover {
    background-color: rgb(128, 128, 128, .75);
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.no-card-link {
    text-align: center;
    background-color: #959595;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    flex-shrink: 1;
    margin: 1rem 5rem; 
    border: none;
}

.no-card-link:hover {
    cursor: not-allowed;
}


.no-card-link:active, .service-card-link {
    border: none;
}

.no-card-link:focus, .service-card-link {
    border: none;
    outline: none;
}

/* -------------------------OFFICER----------------------------- */

.officer-card {
    width: 100%;
    display: flex;
    flex-direction: column;
     justify-content: center;
    align-items: center;
    margin: 2% 0;
    color: #333;
}

.officer-card-name {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    box-sizing: border-box;
}

.officer-card-name > h3 {
    box-sizing: border-box;
    margin: .6rem 0 0 0;
    padding: 0;
}

.officer-card-name > h4 {
    color: #002052;
    margin: 0 0 .6rem 0;
}

.officer-card-body {
    display: flex;
    margin-top: 1%;
    align-items: left;
}

.officer-card-image {
    max-width: 50%;
}

.officer-card-image > img {
    max-width: 100%;
    max-height: 300px;
    height: auto;

}

.officer-card-detail {
    justify-self: left;
    font-size: .8rem;
}

.officer-card-detail > p {
    padding: 1% 3% 2% 3%;
    text-align: justify;
}

/* -------------------------DEPARTMENTS----------------------------- */
.department-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.department-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
    box-sizing: border-box;
}

.department-card-title {
    text-align: center;
}

.department-card-title > h4 {
    margin-bottom: .6rem;
}

.department-expand-button {
    border: 0;
    padding: 1rem 6rem;
    background-color: #808080;
    color: white;
    outline: none;
    margin-top: .6rem;
}

.department-expand-button:hover {
    background-color: rgb(128, 128, 128, .75);
    cursor: pointer;
    
}

.department-card-expand {
    color: black;
    transition: all 0.5s ease-out;
    max-height: 3000px;
    opacity: 1;
}

.department-card-expand > p, .department-content-group {
    background-color: #ffffff;
    text-align: left;
}

.department-card-expand > p, .department-content-group > p {
    padding: 1rem 1rem;
    margin: 0;
}

.department-card-hidden {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .5s ease-out;
}