.jumbo-wrap {
    display: flex;
    flex-direction: row;
    min-height: 40vh;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 2% 10%;
}

.jumbo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 8%;
    padding-right: 8%;
    box-sizing: border-box;
    width: 65%;
}

.jumbo > h1, .jumbo > h3 {
    text-align: center;
    color: #FFFFFF;
    text-shadow: 3px 3px 0 #303030;
}

.jumbo > h3 {
    margin-bottom: 5%;
}

.jumbo > a {
    color: rgb(0, 0, 0) !important; 
    background-color: rgba(255, 255, 255);
    border: 0;
    padding: 18px 25%;
    font-size: 1rem;
}

.jumbo > a:hover {
    color: rgb(0, 0, 0) !important; 
    background-color: rgba(255, 255, 255, .75);
    cursor: pointer;
}