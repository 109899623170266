.panel, .panel-no-overlay {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    font-size: 16px !important;
    overflow: hidden;
}

.panel-no-overlay {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-attachment: fixed;
}

.panel-image-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    background-attachment: fixed;
    z-index: -1;
    opacity: .3;
}

.panel-image-content {
    width: 100%;
    position: relative;
    z-index: 20;
}