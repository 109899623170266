.privacy-wrapper {
    position: fixed;
    bottom: 0;
    min-height: 40px;
    width: 100%;
    background-color: #007aa3;
    display: flex;
    z-index: 100;
    padding: 2% 10%;
    box-sizing: border-box;
    color: white;
}

@media only screen and (max-width: 768px) {
    .privacy-wrapper {
        padding: 3% 5%;
    }
  }

.privacy-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-right: 2rem;
}

.privacy-content > h4 {
    margin: 0 0 .6rem 0
}

.privacy-content > p {
    margin: 0rem;
}

.privacy-content > p > span {
    text-decoration: underline;
    color: white;
}

.privacy-content > p > span:hover {
    cursor: pointer;
}

.privacy-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-button > button {
    background-color: #2ecbff;
    border: 2px solid rgb(255, 255, 255);
    padding: .6rem 2rem;
    font-weight: bold;
    color: white;
}

.privacy-button > button:hover {
    cursor: pointer;
    background-color: #0078ae;
}

.privacy-statement {
    padding: 2% 5%;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
}

.policy-modal-close {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    padding-right: 10%;
    position: absolute;
}

.policy-modal-close > button {
    font-size: 2rem;
    font-weight: bold;
    background-color: transparent;
    border: 0;
    padding-right: 2%;
    padding-top: 1%;
}

.policy-modal-close > button:hover {
    cursor: pointer;
    border: 0;
    outline: 0;
}