.media-video {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 2% 1%;
}

.media-video:hover {
    background-color: #4671af;
    cursor: pointer;
    color: white;
}

.media-video > img {
    width: 100%;
    height: auto;
    margin: 0;
}

.media-video-content > p {
    font-size: .9rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1rem;
}

.active-video {
    background-color: #00467f !important;
    color: white;
}