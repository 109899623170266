.team-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 4%;
    overflow-x: hidden;
}

.team-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
    box-sizing: border-box;
    align-items: center;
}

.team-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 4%;
    color: black;
}

.team-image {
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.team-image > img {
    width: 100%;
    height: auto;
}

.team-culture-box {
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateX(0);
    opacity: 1;
}

.team-where-box {
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateY(0);
    opacity: 1;
}

.team-location-box {
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateY(0);
    opacity: 1;
}

/* .team-culture-box, .team-where-box, .team-location-box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 3%;
} */

.team-culture-box > h3, .team-where-box > h3, .team-location-box > h3 {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    text-align: center;
}

.team-culture-box > p, .team-where-box > p, .team-location-box > p {
    font-size: .9rem;
    margin: .4rem 0;
}

.hidden-teams-top {
    transform: translateY(-100px);
    opacity: 0;
    z-index: -5;
}

.hidden-teams-bottom {
    transform: translateY(100px);
    opacity: 0;
    z-index: -5;
}