.media-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 0 0;
    box-sizing: content-box;
    background-color: #777;
    
}

.media-wrapper > h3 {
    text-align: center;
    font-size: 1.8rem !important;
    color: #f9f9f9;
}

.media-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.media-selected-content {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding-bottom: 2%;
    color: #f9f9f9;
}

.media-video-player {
    width: 60%;
    height: 50vh;
    padding-left: 3%;
    box-sizing: border-box;
}

.media-video-player > iframe {
    display: flex;
    width: 100%;
    height: 100%;
    border: 0;
}

.media-video-description {
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-video-selector {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    background-color: #cccccc;
}

.video-scroller-prev, .video-scroller-next {
    position: sticky;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0 15px;
    user-select: none;
    background-color: rgba(198, 198, 198, .2);
    opacity: .6;
}

.video-scroller-prev:hover, .video-scroller-next:hover {
    background-color: rgba(198, 198, 198, .8);
    opacity: 1;
    cursor: pointer;
}

.video-scroller-prev {
    left: 0;
}

.video-scroller-prev:hover {
    left: 0;
    box-shadow: inset -8px 0 10px -6px white;
}

.video-scroller-next {
    right: 0;
}

.video-scroller-next:hover {
    right: 0;
    box-shadow: inset 8px 0 10px -6px white;
}
