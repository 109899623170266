.nav-v2 {
    background-color: #333333;
    color: #c6c6c6;
    position: fixed;
}

.navbar-toggler-icon {
    color: #c6c6c6 !important;
}

.logo-group {
    display: flex;
    align-items: center;
}

.logo {
    height: 100%;
    max-width: 120px;
}

.link-home:hover {
    cursor: pointer;
}

.logo-group > a {
    color: white;
}

.logo-group > a:hover {
    color: #959595;
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.nav-img {
    height: 60px;
    width: 120px;
}

.nav-link {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    color: #c6c6c6;
    font-size: 1rem;
}

.btn-60 {
    height: 60px;
}

.nav-link:hover {
    background-color: rgba(133, 133, 133, .3);
    color: #c6c6c6;
    text-decoration: none;
}

.nav-link.active {
    border: 0;
    background-color: #959595;
    color: black;
}

.nav-link.active:hover {
    background-color: #959595;
    color: black;
}

.career-link {
    color: gold !important;
}

