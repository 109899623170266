.contact {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.contact-map {
    border: 0;
    width: 100%;
    height: 100%;
    min-height: 400px;
    /* box-shadow: 20px 40px 50px black; */
}