.services-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    color: #777777;
}

.services-wrapper > h2 {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

.services-card-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.services-card-group-row-1, .services-card-group-row-2 {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

/* --------------------OLD-------------------------- */
.services {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
}

.services-header {
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    background-color: #0078ae;
    z-index: 1;
}

.services-header > h2 {
    width: 100%;
    text-align: center;
}

.services-header-buttons {
    display: flex;
    justify-content: space-evenly;

}

.services-header-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #808080;
    padding: 18px 42px;
    width: 100%;
    text-align: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.services-header-button > span {
    margin-top: 5%;

}

.services-details-container-expanded, .services-details-container-expanded-exiting {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.services-details-container-expanded {
    animation: grow 800ms ease-in-out;
}

.services-details-container-expanded-exiting {
    animation: shrink 800ms ease-in-out;
}

@keyframes grow {
    from {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

    to {
        overflow: visible;
        max-height: 2000px;
        opacity: 1;
    }
}

@keyframes shrink {
    from {
        overflow: hidden;
        max-height: 2000px;
        opacity: 1;
    }

    to {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }
}

/* Details */
.services-details, .services-details-expanded {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
    box-sizing: border-box;
    font-size: .9rem;
    background-color: #fbfbfb;
    color: rgb(0, 0, 0);
}

.services-details > h3, .services-details-expanded > h3 {
    color: #323232;
}

.services-details-col-2 {
    color: rgb(171, 171, 171);
    display: flex;
}

@keyframes shrink {
    0% {
        /* max-height: 2000px; */
        overflow: hidden;
        opacity: 1;
    }
    
    100% {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
    }
}

@keyframes show {
    0% {
        /* max-height: 2000px; */
        overflow: hidden;
        opacity: 0;
    }
    
    100% {
        opacity: 1;
        /* max-height: 2000px; */
        overflow: hidden;
    }
}

.services-details-close {
    font-size: 1.4rem;
    background-color: rgba(164, 164, 164, 0.6);
    box-shadow: 0;
    border: 0;
    margin: 1% auto;
    color: #6c6b6b;
}

.services-details-close:hover {
    cursor: pointer;
    background-color: rgba(164, 164, 164, 0.8);
}

.services-details-close:focus {
    outline: 0;
}

.services-details-close > i {
    margin: 0.4rem;
}



/*@font-face{font-family:icomoon;src:url(/fonts/icomoon.eot?-1wstgv);src:url(/fonts/icomoon.eot?#iefix-1wstgv) format('embedded-opentype'),url(/fonts/icomoon.woff?-1wstgv) format('woff'),url(/fonts/icomoon.ttf?-1wstgv) format('truetype'),url(/fonts/icomoon.svg?-1wstgv#icomoon) format('svg');font-weight:400;font-style:normal}[class^=icon-],[class*=" icon-"]{font-family:icomoon;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-p_tracker_icon_ft:before{content:"\e600"}.icon-p_tracker_icon_pt:before{content:"\e601"}.icon-p_tracker_icon:before{content:"\e602"}*/