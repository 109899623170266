.App {
  margin: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.element {
  width: 100%;
  padding: 0;
  margin: 0;
}

.bold {
  font-weight: bold !important;
}
