.contact-form-wrapper {
    background-color: #333;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.form-submitted {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 2rem;
    font-weight: bold;
    box-sizing: border-box;
    background-color: #333;
    color: white;
}

.form-submitted-modal {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vw;
    z-index: 9999;
    position: static;
    margin: 0 auto;
    background-color: white;
}

.form-modal-close {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    padding-right: 10px;
    position: absolute;
}

.form-modal-close > button {
    font-size: 2rem;
    font-weight: bold;
    background-color: transparent;
    border: 0;
    padding-right: 2%;
    padding-top: 1%;
}

.form-modal-close > button:hover {
    cursor: pointer;
    border: 0;
    outline: 0;
}

.form-submitted-modal-iframe{
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
}

.drop-shadow {
    background-color: rgba(61, 61, 61, .5);
}

.contact-form-header {
    background-color: #333;
    color: #f9f9f9;
    box-sizing: border-box;
    width: 100%;
    padding: 5% 5%;
    display: flex;
    justify-content: space-between;
    
}

.drop-shadow > .contact-form-header {
    background-color: rgba(61, 61, 61, .8);
}

.contact-form-header > div:first-of-type > h2 {
    font-weight: 500;
    margin: 0;
    font-size: 1.2rem;    
}

.contact-form-header > div:first-of-type > p {
    font-size: .9rem;
    margin: 3% 5% 0 0;
}

.contact-form-header > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    opacity: .6;
}

.contact-form-wrapper > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.contact-form-wrapper > form > * {
    flex-grow: 1;
    width: 100%;
    margin: 10px;
    padding: 1rem .6rem;
    font-size: 1rem;
}

.contact-form-wrapper > form > *:focus {
    outline: 0;
}

.contact-form-wrapper > form > textarea {
    font-family: 'Arial';
}

.contact-form-wrapper > form > button {
    background-color: #959595;
    border: 0;
    font-weight: bold;
}

.contact-form-wrapper > form > button:hover {
    background-color: #c6c6c6;
    border: 0;
    font-weight: bold;;
    cursor: pointer;
}

.form-error {
    display: flex;
    outline: 3px solid red;
}

.form-error-note {
    background-color: rgb(13, 148, 237);
    color: #f9f9f9;
}
