.location-wrapper {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background-color: #f9f9f9;
    width: 100%;
    flex-wrap: wrap;
    color: #808080;
}

.location-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 2% 10%;
}

@media only screen and (max-width: 768px) {
    .location-content {
        padding: 2% 2%;
    }
  }

.location-content-group {
    display: flex;
    width: 50%;
    box-sizing: border-box;
    padding-right: 8%;
    flex-direction: column;
    justify-content: center;
}

.location-wrapper-text-content {
    /* background-color: rgba(149, 149, 149, .6); */
    padding: 4% 6%;
    border-radius: 15px;
    box-sizing: border-box;
    transition: all 1s ease-out;
    transform: translateX(0);
    opacity: 1;
}

.location-wrapper-text-content > h3 {
    font-size: 1.2rem;
    text-align: center;
    margin: 0 0 2% 0;
}

.location-wrapper-text-content > p {
    margin: 0;
}

.location-content-locations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}
.location-content-locations > p {
    font-weight: bold;
}
/* background-color: rgba(149, 149, 149, .6); */

.location-wrapper > iframe {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    box-sizing: border-box;
    border: 0;
    justify-content: center;
}