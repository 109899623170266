.team-bar-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    color: #959595;
    margin-bottom: 4%;
}

.team-bar-header {
    width: 100%;
    text-align: center;
}

.team-bar-header > h3 {
    font-size: 1.8rem !important;
}

.team-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.row {
    display: flex;
    justify-content: center;
}

.team-bar-button-group {
    padding: 0;
}
