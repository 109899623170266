.client-wrapper {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    padding: 2% 10%;
    color: #959595;
}

@media only screen and (max-width: 768px) {
    .client-wrapper {
        padding: 2% 2%;
    }
  }

.client-content {
    display: flex;
    width: 50%;
    box-sizing: border-box;
    padding-left: 8%;
    flex-direction: column;
    justify-content: center;
}

.client-wrapper-text-content {
    /* background-color: rgba(149, 149, 149, .6); */
    padding: 4% 6%;
    border-radius: 15px;
    box-sizing: border-box;
    transition: all 1s ease-out;
    transform: translateX(0);
    opacity: 1;
}

.client-wrapper-text-content > h3 {
    font-size: 1.2rem;
    text-align: center;
    margin: 0 0 2% 0;
}

.client-wrapper-text-content > p {
    margin: 0 0 0 0;
}

.client-image {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
}

.client-image > img {
    width: 100%;
    height: auto;
}

.hidden-right {
    transform: translateX(500px);
    opacity: 0;
}