.job-item {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: white;
    color: #959595;
}

.job-item-image {
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
}

.job-item-image > img {
    width: 100%;
    height: 80%;
    padding: auto;
    margin-top: 1rem;
}

.job-item-name {
    text-align: center;
    border-top: 1px solid  #00467f;
}

.job-item-name > h3, .job-item-description-group > h4 {
    font-weight: 600 !important;
}

.job-item-name > h3 {
    font-size: 1rem;
}

.job-item-button-group {
    text-align: center;
}

.job-item-button {
    background-color: #808080;
    color: white;
    padding: 1rem 5rem;
    border: 0;
    cursor: pointer;
}

.job-item-button:hover {
    background-color: rgb(128, 128, 128, .75);
}

.job-item-button:focus {
    outline: none;
}

.job-item-description {
    display: block;
    transition: max-height 1s ease-in-out;
}

.job-item-description-group {
    font-size: .9rem;
}

.job-item-description-hidden {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
