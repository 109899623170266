html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: 'Arial', sans-serif !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

h1 {
  font-size: 2.2rem !important;
  font-family: 'Arial', sans-serif !important;
}

h2 {
  font-size: 2rem !important;
  font-family: 'Arial', sans-serif !important;
}

h3 {
  font-size: 1.2rem !important;
  font-family: 'Arial', sans-serif !important;
}

h4 {
  font-size: 1rem !important;
  font-family: 'Arial', sans-serif !important;
}

p, li {
  font-size: .9rem !important;
  font-family: 'Arial', sans-serif !important;
}
