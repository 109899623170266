.news-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
}

.news-wrapper > h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: #959595;
}

.news-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news-scroller {
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
}

.news-content > iframe {
    width: 80%;
    min-height: 90vh;
    /* max-height: 90vh; */
    transition: all 1s ease;
}

.hidden-article {
    max-height: 0 !important;
    min-height: 0 !important;
}