.industry-wrapper {
    width: 100%;
    box-sizing: border-box;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    padding: 2% 10%;
    color: #808080;
}

@media only screen and (max-width: 768px) {
    .industry-wrapper {
        padding: 2% 2%;
    }
  }

.industry-content {
    display: flex;
    width: 80%;
    box-sizing: border-box;
    padding: 0 8% 0 8%;
    flex-direction: column;
    justify-content: center;
    
}

.industry-wrapper-text-content {
    /* background-color: rgba(149, 149, 149, .6); */
    padding: 4% 6%;
    border-radius: 15px;
    box-sizing: border-box;
    transition: all 1s ease-out;
    transform: translateX(0);
    opacity: 1;
}

.industry-wrapper-text-content > h3 {
    font-size: 1.2rem;
    text-align: center;
    margin: 0 0 5% 0;
}

.industry-wrapper-text-content > p {
    margin: 0;
}

.icons-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
}

.industry-icon-group {
    display: flex;
    flex-direction: column;
    font-size: .5rem;
    width: 16%;
    word-wrap:normal;
    text-align: center;
    align-items: center;
    font-weight: bold;
    
}

.industry-icon-group > img {
    width: 50px;
    height: auto;
}

.hidden-left {
    transform: translateX(-500px);
    opacity: 0;
}