.footer {
    padding: 1rem 15%;
    background-color: white;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    font-size: .8rem;
}

@media only screen and (max-width: 768px) {
    .footer {
        padding: 1rem 5%;
    }
  }

.footer-icon-group {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.footer-icon {
    font-size: 1.8rem;
    margin: 0 .6rem;
    color: #003455;
}