.carousel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: black;
    padding-bottom: 2%;
}

.carousel-header {
    display: flex;
    position: static;
    justify-content: center;
    padding: 2% 0 0;
    width: 80%;
}

.carousel-header > h3 {
    font-size: 1.8rem !important;
    font-weight: bold;
    color: white;
    text-shadow: 3px 3px 6px black;
    /* border-bottom: 1px solid #c7c000; */
    text-align: center;
    padding-bottom: 1%;
}

.carousel-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.carousel-items {
    display: flex;
    justify-content: space-evenly;
}

.carousel-items-expanded {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-evenly;
}

.carousel-expand {
    display: flex;
    font-size: 1.4rem;
    border-radius: .2rem;
    background-color: rgba(164, 164, 164, 0.6);
    box-shadow: 0;
    border: 0;
    margin: 1% auto;
    padding: 0 3%;
    text-decoration: none;
    color: black;
}

.carousel-expand:hover {
    cursor: pointer;
    background-color: rgba(164, 164, 164, 0.8);
    text-decoration: none;
}

.carousel-expand:focus {
    outline: 0;
}

.carousel-expand > i {
    margin: 0.4rem;
}