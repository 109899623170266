.who-we-are {
    padding: 0 10%;
    box-sizing: border-box;
    color: #959595;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.who-we-are > h2 {
    text-align: center;
    font-weight: 500;
    margin: 0;
}

.who-we-are-image {
    width: 50%;
    margin: 2% 0;
}

.who-we-are > hr:nth-of-type(1) {
    box-shadow: 0 -4px 5px black;
    margin-bottom: 0;
    margin-top: 5%;
}

.who-we-are > hr:nth-of-type(2) {
    box-shadow: 0 4px 5px black;
    margin-top: 0;
    margin-bottom: 5%;
}

.who-we-are-content {
    display: flex;
}

.who-we-are-content p {
    margin: 1% 0;
}

.who-we-are-col-1, .who-we-are-col-2, .who-we-are-col-3 {
    transition: transform 1s ease-out, opacity 1s ease-out, ;
    box-sizing: border-box;
}

.who-we-are-col-1 {
    box-sizing: border-box;
    min-width: 35%;
    justify-content: space-around;
}

.who-we-are-col-2, .who-we-are-col-3 {
    align-self: flex-start;
}

.who-we-are-img {
    height: auto;
    width: 100%;
    transition: transform 1s ease-out, opacity 1s ease-out, ;
}

.not-entered-img {
    opacity: 0;
    transform: translateX(-50%);
}

.not-entered-col-2 {
    opacity: 0;
    transform: translateY(-100px);
}

.not-entered-col-3 {
    opacity: 0;
    transform: translateY(100px);
}